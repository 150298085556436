import { addProduct, removeProduct } from "./actions";
import { toast } from 'react-toastify';

export const addProductThunk = product => {
  return (dispatch) => {
    const notify = () => toast("Added to the Cart");
    const list = JSON.parse(localStorage.getItem("@products")) || [];

    list.push(product);
    localStorage.setItem("@products", JSON.stringify(list));
    notify();
    dispatch(addProduct(product));
  }
}

export const removeFromProductThunk = name => (dispatch, getState) => {
  const { cart } = getState();

  const listFiltered = cart.filter(ele => ele.name !== name);
  localStorage.setItem("@products", JSON.stringify(listFiltered));
  
  dispatch(removeProduct(listFiltered));
}
