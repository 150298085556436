import { useDispatch } from 'react-redux';
import { Container } from './styles'
import { addProductThunk, removeFromProductThunk } from '../../store/modules/Cart/thunks';

export default function Card({ obj, isBuy=false }) {
  const { name, price, image} = obj
  const dispatch = useDispatch();

  const handleClickAdd = () => {
    dispatch(addProductThunk(obj))
  }

  const handleClickRemove = () => {
    dispatch(removeFromProductThunk(name))
  }
  

  return (
    <Container>
      <h2>Name: {name}</h2>
      <img src={image} alt={name}/>
      <span>Price: RS{price}</span>
      {isBuy ? (
        <button onClick={handleClickAdd}>Add Cart</button>
      ) : (
        <button onClick={handleClickRemove}>Remove Product</button>

      )}
      
    </Container>
  )
}
