const stateDefault = [
	{name: "Echo Dot", price: 279.00, image: "https://m.media-amazon.com/images/I/71JB6hM6Z6L._AC_UL320_.jpg"},
	{name: "Kindle Paperwhite", price: 499.00, image: "https://m.media-amazon.com/images/I/61ldUg+PqQL._AC_UL320_.jpg"},
	{name: "Cablo", price: 49.90, image: "https://images-na.ssl-images-amazon.com/images/I/51sEck3ZqLL._AC_SX679_.jpg"},
	{name: "Suporte de mesa", price: 197.99, image: "https://images-na.ssl-images-amazon.com/images/I/511U11XLcrL._AC_SY450_.jpg"},
	{name: "Mouse Pad", price: 49.95, image: "https://images-na.ssl-images-amazon.com/images/I/418WwgA4tjL._AC_SY450_.jpg"},
	{name: "Suporte Nootbook", price: 43.90, image: "https://images-na.ssl-images-amazon.com/images/I/51NM5D1VN%2BL._AC_SX355_.jpg"},
	{name: "Nootbook Predator", price: 9.990, image: "https://images-na.ssl-images-amazon.com/images/I/51AiJscIFPL._AC_SY355_.jpg"},
	{name: "Teclado/Mouse", price: 149.99, image: "https://images-na.ssl-images-amazon.com/images/I/61ilesVBjcL._AC_SX450_.jpg"},
	{name: "Pendrive", price: 119.99, image: "https://images-na.ssl-images-amazon.com/images/I/51JBr4UZfxL._AC_SY741_.jpg"},
	{name: "Echo Dot", price: 279.00, image: "https://m.media-amazon.com/images/I/71JB6hM6Z6L._AC_UL320_.jpg"}
]

const projectsReducer = (state = stateDefault, action ) => {
  return state
}

export default projectsReducer;