import { GlobalStyle } from './styles';
import Routes from './routes'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (<>
  
    <GlobalStyle />
    <Routes />
    <ToastContainer 
      position="top-center"
      autoClose={2000}
      pauseOnFocusLoss={false}
    />
    </>
  );
}

export default App;
