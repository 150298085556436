const cartReducer = (state = [], action) => {
  switch (action.type) {
    case "@add/PRODUCT":
      const { product } = action

      return state = [...state, product]

    case "@remove/PRODUCT":
      return action.list

    default:
      const products = JSON.parse(localStorage.getItem("@products")) || []
      return products;
  }
};

export default cartReducer;