import { combineReducers, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import projectsReducer from './modules/Products/reducer';
import cartReducer from './modules/Cart/reducer';

const reducers = combineReducers({
  store: projectsReducer,
  cart: cartReducer
})

const store = createStore(reducers, applyMiddleware(thunk))

export default store;