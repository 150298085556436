import Store from '../pages/Store'
import Cart from '../pages/Cart'
import Menu from '../components/Menu'

import React from 'react'
import { Route, Switch } from 'react-router-dom';

export default function Routes() {
  return (<>
    <Menu />
    <Switch>
      <Route exact path="/">
        <Store />
      </Route>
      
      <Route exact path="/cart">
        <Cart />
      </Route>
    </Switch>
  </>
  )
}
