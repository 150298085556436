import styled from "styled-components";

export const Container = styled.div`
  height: 80px;
  background-color: #131921;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 100px;

  font-size: 1.5rem;

  svg {
    transform: translateY(4px);
  }

  button {
    padding: 7px 15px;
  }
`;