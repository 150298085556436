import { Container } from './styles';
import Card from '../../components/Card';
import { useSelector } from 'react-redux';

export default function Store() {

  const {store} = useSelector(state => state)
  
  return (
    <Container>
      {store.map((obj, index) =>
        <Card key={index} obj={obj} isBuy/>
      )}
    </Container>
  )
}
