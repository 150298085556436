import { Container } from './styles';
import Card from '../../components/Card';
import { useSelector } from 'react-redux';

export default function Cart() {
  // const products = JSON.parse(localStorage.getItem("@products"));
  const { cart } = useSelector(state => state);

  return (
    
    <Container>
        {cart.map((obj, index) =>
        <Card key={index} obj={obj}/>
      )}
    </Container>
  )
}
