import { Container } from './styles';
import { AiOutlineShoppingCart } from 'react-icons/ai'
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';

export default function Menu() {
  const history = useHistory();
  const { cart } = useSelector(state => state);

  return (
    <Container>
        <button onClick={() => history.push("/")}>Store</button>
        <span>Total: R${cart.reduce((acc, current) => acc + current.price, 0).toFixed(2)}</span>
        <button onClick={() => history.push("/cart")}><AiOutlineShoppingCart />Cart</button>
    </Container>
  )
}
