import styled from "styled-components";

export const Container = styled.div`
  width: 300px;
  height: 400px;
  font-size: 0.8rem;
  background-color: white;
  margin: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
  flex-wrap: wrap;
  border-radius: 10px;
  transition: 1s;

  :hover {
    opacity: 0.8;
  }

  img {
    width: 80%;
    height: 60%;
    object-fit: contain;
    object-position: top;
  }

  span {
    font-size: 1.2rem;
    margin: 10px 0;

  }

  h2 {
    margin: 10px 0;
  }

  button {
    background-color: black;
    color: white;
    padding: 7px 30px;
    margin: 15px 0;
    border-radius: 7px;
    border: 2px solid black;
    transition: 1s;

    :hover {
      border: 2px solid orange;
    }
  }
`;